<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BButton, BModal, BFormFile, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard, BButton, BModal, BFormFile, BFormCheckbox,
  },
  data() {
    return {
      pageArray: [],
      editModal: false,
      editObject: {},

      addModal: false,
      addObject: {
        partName: '',
        partEnum: this.$route.params.typeEnum,
        partOrder: 0,
        partModelId: this.$route.params.modelId,
        partPrice: '',
      }
    }
  },
  mounted() {
    this.mountData()
  },
  methods: {
    async mountData() {
      this.editModal = false
      await this.$http.get(`features/parts/${this.$route.params.modelId}/${this.$route.params.typeEnum}`)
        .then(response => {
          this.pageArray = response.data
          this.pageArray.modifyParts.sort((a, b) => a.partOrder - b.partOrder)
        })
    },
    async tableInteractive(item, index, type) {
      if (type === 'delete') {
        await this.$http.post('features/parts/delete', { partId: item.partId })
            .then(() => { this.mountData() })
      }
      if (type === 'edit') {
        this.editObject = structuredClone(item)
        this.editModal = true
      }
    },
    async saveData(type) {
      if (type === 'create') {
        await this.$http.post('/features/parts/create', this.addObject)
            .then(r => {
              this.addModal = false
              this.addObject = {
                partName: '',
                partEnum: this.$route.params.typeEnum,
                partOrder: 0,
                partModelId: this.$route.params.modelId,
                partPrice: '',
              }
            }).then(() => {
              this.mountData()
            })
      }
      if (type === 'update') {
        let payload = {
          partId: this.editObject.partId,
          partOrder: this.editObject.partOrder,
          partName: this.editObject.partName,
          partPrice: this.editObject.partPrice,
        }
        await this.$http.put('/features/parts/update', payload)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.editModal = false
            this.mountData()
          }).catch(error => {
            let errorMessage = ''
            // eslint-disable-next-line default-case
            switch (error.response.data) {
              case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },

}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card tb-flex-vertical">
      <a class="tb-page-title">Car Parts</a>
      <button class="tb-button bg-primary ml-auto" @click="addModal = true">
        <a class="text-white">Create Car Part</a>
      </button>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%">
              <input type="text" class="form-control" placeholder="#" disabled readonly>
            </th>
            <th style="width: 35%"><input type="text" class="form-control" placeholder="Part Name" disabled readonly>
            </th>
            <th style="width: 15%"><input type="text" class="form-control" placeholder="Part Price" disabled readonly>
            </th>
            <th style="width: 15%"><input type="text" class="form-control" placeholder="Part Order" disabled readonly>
            </th>
            <th style="width: 15%"><input type="text" class="form-control" placeholder="Part Enum" disabled readonly>
            </th>
            <th style="width: 15%">
              <input type="text" class="form-control" placeholder="Options" disabled readonly>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray.modifyParts">
            <td><a>{{ index + 1 }}</a></td>
            <td><a>{{ item.partName }}</a></td>
            <td><a>{{ item.partPrice }}</a></td>
            <td><a>{{ item.partOrder }}</a></td>
            <td><a>{{ item.partEnum }}</a></td>
            <td>
              <button class="tb-button bg-primary" @click="tableInteractive(item, index, 'edit')">
                <span class="text-white">Edit</span>
              </button>
              <button class="tb-button bg-danger ml-1" @click="tableInteractive(item, index, 'delete')">
                <span class="text-white">Delete</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Modal -->
    <b-modal v-model="addModal" hide-header hide-footer centered size="md">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Create Car Par</a>
          <hr>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <label>Part Name</label>
              <input v-model="addObject.partName" type="text" class="form-control" name="modelName">
            </div>
            <div class="col-12 mt-1">
              <label>Part Stock Price (ÖRN: 0.14)</label>
              <input v-model.number="addObject.partPrice" type="text" class="form-control" name="modelStockPrice">
            </div>
            <div class="col-12 mt-1">
              <label>Part Order</label>
              <input v-model.number="addObject.partOrder" type="text" class="form-control" name="modelStockPrice">
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" :disabled="!addObject.partName" @click="saveData('create')">
            Save & Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal v-model="editModal" hide-header hide-footer centered size="md">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Create Car Part</a>
          <hr>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <label>Part Name</label>
              <input v-model="editObject.partName" type="text" class="form-control" name="modelName">
            </div>
            <div class="col-12 mt-1">
              <label>Part Stock Price (ÖRN: 0.14)</label>
              <input v-model.number="editObject.partPrice" type="text" class="form-control" name="modelStockPrice">
            </div>
            <div class="col-12 mt-1">
              <label>Part Order</label>
              <input v-model.number="editObject.partOrder" type="text" class="form-control" name="modelStockPrice">
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" :disabled="!editObject.partName" @click="saveData('update')">
            Update & Close
          </b-button>
        </div>
      </div>
    </b-modal>
  </section>
</template>
